/**
 * Const with param element
 * Layout without footer component
 * Display the second layout without footer
 */
/* Import section */
import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import "../styles/components/layoutwithoutfooter.scss"

/* Declaration function */
const Layoutwithoutfooter = ({ children, className }) => {
  return (
    <>
      <Header className="header-without-login"/>

      <main className={`layout-without-footer ${className}`}>{children}</main>

    </>
  )
}

/* Function propTypes declaration */
Layoutwithoutfooter.propTypes = {
  children: PropTypes.node.isRequired,
}

/* Export function */
export default Layoutwithoutfooter
