/**
 * Get Started page
 * Choice between sign-up Virtual Care or Create account with key
 */
/* Import section */
import { graphql } from "gatsby"
import React from "react"
import Ctabutton from "../components/ctabutton"
import Layoutwithoutfooter from "../components/layoutwithoutfooter"
import Seo from "../components/seo"
import "../styles/pages/get-started.scss"

/* Page function declaration */
const GetStartedPage = ({ data }) => {
  //Const data ACF
  const dataPage = data.wpPage
  const dataAcf = dataPage.acfPageGetstarted

  return (
    <Layoutwithoutfooter>
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
        classbody="no-cookies"
      />

      <section className="section-getstarted">
        <h1 className="title-level-1">Get Started</h1>
        <div className="flex-content">
          <div className="flex-item cta-createaccount">
            <h2>{dataAcf.titleH2CreateAccount}</h2>
            <div dangerouslySetInnerHTML={{ __html:dataAcf.textH2CreateAccount}} />
            <Ctabutton ctaLabel="Create Your Account" ctaLink="/create-account" otherStyle="createaccount-getstarted" />
          </div>
          {/* <div className="flex-item cta-signup">
            <h2>{dataAcf.titleH2SignupVc}</h2>
            <div dangerouslySetInnerHTML={{ __html:dataAcf.textH2SignupVc}} />
            <Ctabutton ctaLabel="Sign Up Here" ctaLink="/virtual-care/sign-up" otherStyle="signup-getstarted"/>
          </div> */}
        </div>
      </section>

    </Layoutwithoutfooter>
  )
}

/* Query page */
export const query = graphql`
  query {
    wpPage(slug: {eq: "get-started"}) {
      acfPageGetstarted {
        titleH1
        titleH2CreateAccount
        titleH2SignupVc
        textH2CreateAccount
        textH2SignupVc
      }
      seo {
        metaDesc
        title
      }
    }
  }
`
/* Export page informations */
export default GetStartedPage


